<template>
  <fragment>
    <h3>PAYMENT CYCLE</h3>
    <form-select
      key="payCycle"
      :value="value.payCycle"
      label="Default Pay Cycle"
      name="payCycle"
      default-choice="Select"
      :options="payCycles"
      option-label="label"
      option-value="id"
      rules="required"
      :width="424"
      :class-name="{ 'margin-bottom-zero': payCycle === 'NEXT_DAY' }"
      @input="
        payCycleSelected($event);
        updatePaymentForm('payCycle', $event);
      "
    />
    <template v-if="flag || isPayCycleSelected">
      <div v-if="payCycle === 'NEXT_DAY'" class="margin-bottom-16">
        <p>Typical partners will be paid multiple times a day</p>
        <form-select
          key="secondaryPayCycle"
          :value="value.secondaryPayCycle"
          label="Secondary Pay Cycle"
          name="secondaryPayCycle"
          default-choice="Select"
          :options="secondaryPayCycles"
          option-label="label"
          option-value="id"
          rules="required"
          :width="424"
          :class-name="{ 'margin-bottom-zero': payCycle === 'NEXT_DAY' }"
          @input="updatePaymentForm('secondaryPayCycle', $event)"
        />
        <p :style="{ width: '424px' }">Partners that cannot be paid on the primary cycle (ie. those with garnishments) will default to this cycle</p>
      </div>
      <day-selector :value="value.startDay" :label="label.startDay" :width="424" :required="true" @change="updatePaymentForm('startDay', $event)" />
      <day-selector :value="value.payDay" :label="label.payDay" :width="424" :required="true" @change="updatePaymentForm('payDay', $event)" />
      <div class="module-pay">
        <h3>PAYMENT PROCESSOR</h3>
        <form-select
          key="paymentProcessor"
          :value="value.paymentProcessor"
          name="paymentProcessor"
          :default-choice="processorDefault"
          :disabled="payCycle === 'NEXT_DAY'"
          :options="paymentProcessors"
          option-label="label"
          option-value="id"
          rules="required"
          :width="424"
          @input="updatePaymentForm('paymentProcessor', $event)"
        />
      </div>
      <h3>SUBMISSIONS DUE</h3>
      <p class="payment-submission-note submission-note-width">
        Submission due date is the deadline for client submissions. Overdue submissions can be tracked in the Accounting module under client invoicing.
      </p>
      <div v-if="payCycle !== 'NEXT_DAY'" class="Payment-submission-due">
        <div>
          <day-selector :value="value.submissionDueDay" :width="200" :required="true" @change="updatePaymentForm('submissionDueDay', $event)" />
        </div>
        <div class="field submissionTime">
          <label for="SubmissionDueTime">Time</label>
          <input :value="value.submissionDueTime" type="time" name="SubmissionDueTime" required @focusout="updatePaymentForm('submissionDueTime', $event.target.value)" />
        </div>
      </div>
      <div v-else class="submission-note-width">
        <label for="SubmissionDueTime">Time</label>
        <input value="01:00" type="time" name="SubmissionDueTime" required disabled @input="updatePaymentForm('submissionDueTime', $event.target.value)" />
      </div>
    </template>
  </fragment>
</template>

<script>
import { FormSelect, DaySelector } from "@/components";
import { Fragment } from "vue-fragment";
import PayCycle from "@/modules/shared/finances/model/PayCycle";
import PaymentProcessor from "@/modules/shared/finances/model/PaymentProcessor";

export default {
  name: "PaymentForm",
  components: { FormSelect, Fragment, DaySelector },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    flag: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedPayCycle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isPayCycleSelected: false,
      payCycle: "",
    };
  },
  computed: {
    payCycles() {
      return PayCycle.getPayCycleOptions();
    },
    paymentProcessors() {
      return PaymentProcessor.getPaymentProcessorOptions();
    },
    secondaryPayCycles() {
      return PayCycle.getSecondaryPayCycleOptions();
    },
    label() {
      if (this.payCycle === "NEXT_DAY") {
        return {
          startDay: "Secondary Cycle Start Day",
          payDay: "Secondary Pay Day",
        };
      }
      return {
        startDay: "Cycle Start Day",
        payDay: "Pay Day",
      };
    },
    processorDefault() {
      return this.payCycle === "NEXT_DAY" ? "Branch" : "Select";
    },
  },
  mounted() {
    this.$props.selectedPayCycle ? (this.payCycle = "NEXT_DAY") : null;
  },
  updated() {
    if (this.payCycle === "NEXT_DAY") {
      this.$props.value["paymentProcessor"] = "Branch";
      this.$props.value["submissionDueTime"] = "01:00";
    }
  },
  methods: {
    payCycleSelected($event) {
      this.payCycle = $event;
      this.isPayCycleSelected = true;
    },
    updatePaymentForm(key, value) {
      this.$emit("input", { ...this.$props.value, [key]: value });
    },
  },
};
</script>
